<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Preferências dos grupos"
        p-read-link="groupPreference.read.one"
        p-create-link="groupPreference.create"
        p-name="GroupsPreferences"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwGroupPreferenceRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'descrição',
                value: 'descricao'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    },
}
</script>